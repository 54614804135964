<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
    <!-- 主体认证页面 -->
    <div class="subject">
        <main-steps></main-steps>
        <div class="subject_main">
            <div class="subject_main_top">
                <span><img :src="titleIcon" alt="" /></span>
                <span class="subject_main_top_font">投诉人帐号主体信息</span>
            </div>
            <div class="alert">
                <el-alert type="warning" show-icon @close="close" v-show="showaAlert">
                    <template slot="title">
                        <p class="alert-text">
                            您的帐号主体材料未通过审核，请您重新填写并提交，失败详情请至
                            <span @click="goRecord">我的反馈</span>
                            中查看
                        </p>
                    </template>
                </el-alert>
            </div>
            <el-form label-position="right" label-width="125px">
                <el-form-item label="投诉人类型">
                    <el-radio-group
                        v-model="radio"
                        :disabled="disabled"
                        size="small"
                        fill="rgba(45,99,224,0.14)"
                        text-color="#2D63E0"
                    >
                        <el-radio-button label="组织"> </el-radio-button>
                        <el-radio-button label="个人" style="margin-left: 12px; border-radius: 4px"> </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <div class="subject-tip">
                    请填写您本人或组织的真实身份信息， 若您是代理人， 注意此处不要写成您所代理的权利人信息
                </div>
                <organization v-show="isshow" @edit="edit1"></organization>
                <personal v-show="!isshow" @edit="edit1"></personal>
            </el-form>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';
import Organization from '@/components/subject/Organization.vue';
import Personal from '@/components/subject/Personal.vue';
import { info } from '@/api/getData.js';

export default {
    name: 'subject',
    components: {
        'main-steps': MainSteps, // 步骤条
        organization: Organization, // 组织
        personal: Personal, // 个人
    },
    data() {
        return {
            titleIcon: require('@/assets/img/title2.png'),
            radio: '个人',
            disabled: true,
            isshow: false,
            showaAlert: false,
        };
    },
    methods: {
        goRecord() {
            this.$router.push({
                path: '/record',
            });
        },
        close() {
            this.showaAlert = false;
        },
        edit1() {
            this.disabled = false;
        },
        nextTick() {},
        activated() {
            this.$nextTick(() => {
                this.$refs.Organization();
                this.$refs.Personal();
            });
        },
    },
    mounted() {
        if (sessionStorage.getItem('dis')) {
            this.disabled = false;
        }
        info().then((res) => {
            sessionStorage.setItem('extras', res.data.extras.public_payment);
            if (res.data.user_type !== 1 && res.data.user_type !== 2) {
                this.disabled = false;
            }
            if (res.data.user_status === 2) {
                this.disabled = false;
                this.showaAlert = true;
            } else {
                this.showaAlert = false;
            }
            if (res.data.user_type === 1) {
                this.radio = '个人';
                this.isshow = false;
            } else {
                this.radio = '组织';
                this.isshow = true;
            }
            if (sessionStorage.getItem('type')) {
                if (sessionStorage.getItem('type') === 'true') {
                    this.radio = '组织';
                    this.isshow = true;
                } else {
                    this.radio = '个人';
                    this.isshow = false;
                }
                sessionStorage.setItem('type1', this.isshow);
            } else {
                this.$store.commit('changeType', this.isshow);
                sessionStorage.setItem('type1', this.isshow);
            }
        });
    },
    watch: {
        radio(newValue) {
            if (newValue === '组织') {
                this.isshow = true;
            } else {
                this.isshow = false;
            }
            sessionStorage.setItem('type1', this.isshow);
            this.$store.commit('changeType', this.isshow);
        },
    },
};
</script>

<style lang="less" scoped>
.subject-tip {
    width: 735px;
    text-align: left;
    text-indent: 15px;
    margin: 5px 0 5px 115px;
    background: #fff;
    height: 30px;
    line-height: 30px;
    color: rgb(217, 218, 227);
    font-size: 14px;
}
.subject {
    background: #ffffff;
    box-shadow: 0 10px 32px 10px rgba(175, 188, 220, 0.02);
    border-radius: 4px;
    width: 1200px;
    margin: auto;
}
::v-deep .alert {
    width: 580px;
    margin: 20px 0;
    position: relative;
    left: 40px;
    .alert-text span {
        color: rgb(41, 105, 176);
        text-decoration: underline;
        &:hover {
            cursor: pointer;
        }
    }
    [class*=' el-icon-'],
    [class^='el-icon-']:hover {
        color: #e6a23c;
    }
    .el-pagination [class*=' el-icon-'],
    [class^='el-icon-']:hover {
        color: #e6a23c;
    }
    .el-alert__closebtn {
        top: 12px;
    }
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label {
    display: none;
}
::v-deep .el-radio-button__inner:hover {
    color: #2d63e0;
}
::v-deep .el-radio-button.is-active span {
    border-color: #2d63e0 !important;
}
::v-deep .el-radio-button span {
    background: #f5f7fc;
    border-radius: 4px;
    box-shadow: none !important;
    border: 1px solid transparent;
}
::v-deep .el-radio-button__orig-radio:disabled + .el-radio-button__inner {
    background: #f5f7fc;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
    border: 1px solid transparent;
}
::v-deep .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: none;
}
::v-deep .el-upload {
    width: 120px;
    height: 75px;
}
::v-deep .el-upload--picture-card {
    border: 1px dashed rgb(207, 212, 223);
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-actions {
    transition: none;
}
::v-deep .el-upload-list__item {
    transition: none;
}
::v-deep .el-radio-button--small .el-radio-button__inner {
    font-size: 14px;
}
::v-deep .el-radio-button__inner {
    border: none;
}
::v-deep .is-active span {
    box-shadow: rgb(45, 99, 224 / 14%) 0px 0px 0px 0px !important;
}
.subject_main {
    min-height: 100vh;
    width: 800px;
    margin-left: 245px;
}
/deep/ .el-radio-button:first-child {
    .el-radio-button__inner {
        border-radius: 4px;
    }
}
/deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 4px;
}
.subject_main_top {
    width: 197px;
    height: 20px;
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-top: 20px;
}
.subject_main_top span img {
    width: 11px;
    height: 11px;
    position: relative;
    top: -3px;
}
.subject_main_top_font {
    font-size: 20px;
    color: #1e2024;
    line-height: 20px;
    height: 20px;
    font-weight: bold;
}
.el-form-item {
    font-size: 14px;
    color: #383d47;
    text-align: left;
    margin-bottom: 0;
}
</style>